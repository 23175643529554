var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"pa-3"},[_c('modal-loading',{attrs:{"is-loading":_vm.saving,"message":"表示順を保存しています..."}}),_c('modal-confirmation',{attrs:{"state-modal":_vm.isModal,"title":"表示順を保存してもよろしいですか？","color":"primary","label":"保存する"},on:{"hideModal":_vm.toggleModal,"apply":_vm.save}}),_c('div',{staticClass:"d-flex align-start flex-wrap mb-10"},[_c('h2',{staticClass:"mb-6 mr-8"},[_vm._v("ページ一覧")]),_c('v-btn',{staticClass:"mr-5",attrs:{"large":"","dark":"","rounded":"","color":"#2196F3","to":{ name: 'pages_create' }}},[_c('v-icon',{staticClass:"mr-3",attrs:{"dark":""}},[_vm._v(" mdi-dock-window ")]),_vm._v(" 新規登録 ")],1)],1),_c('div',{staticClass:"d-flex justify-space-between flex-wrap"},[_c('div',{staticClass:"d-flex"},[_c('v-switch',{attrs:{"disabled":_vm.pages.length === 0 ||
          (_vm.$route.query.words && _vm.$route.query.words.length > 0) ||
          (_vm.$route.query.order && _vm.$route.query.order.length > 0),"label":"表示順の編集"},model:{value:(_vm.sortableState),callback:function ($$v) {_vm.sortableState=$$v},expression:"sortableState"}}),_c('v-btn',{staticClass:"mt-3 ml-4 grey lighten-3",attrs:{"disabled":!_vm.sortableState ||
          _vm.pages.length === 0 ||
          (_vm.$route.query.words && _vm.$route.query.words.length > 0) ||
          (_vm.$route.query.order && _vm.$route.query.order.length > 0)},on:{"click":_vm.toggleModal}},[_vm._v(" 保存 ")])],1),_c('div',{staticClass:"d-flex col-5 pa-0"},[_c('v-text-field',{attrs:{"value":_vm.params.words,"type":"search","outlined":"","dense":"","prepend-inner-icon":"mdi-magnify","height":"44px"},on:{"change":(value) => {
            _vm.params.words = value;
          }}})],1)]),(_vm.pages.length !== 0)?_c('section',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.pages,"page":_vm.params.page,"items-per-page":_vm.perPage,"search":_vm.params.words,"custom-filter":_vm.search,"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"no-results-text":"データがありません。","hide-default-footer":""},on:{"update:page":function($event){return _vm.$set(_vm.params, "page", $event)},"page-count":function($event){_vm.pageCount = $event},"update:sort-desc":function($event){return _vm.updateSort($event)}},scopedSlots:_vm._u([{key:"item.id",fn:function(){return [(_vm.sortableState)?_c('v-icon',{attrs:{"color":"#888"}},[_vm._v(" mdi-drag ")]):_vm._e()]},proxy:true},{key:"item.order",fn:function({ item }){return [_vm._v(" "+_vm._s(item.order)+" ")]}},{key:"item.title",fn:function({ item }){return [(!_vm.sortableState)?_c('router-link',{attrs:{"to":{ name: 'pages_edit', params: { id: item.id, from: _vm.params } }}},[_vm._v(" "+_vm._s(item.title)+" ")]):_c('div',[_vm._v(" "+_vm._s(item.title)+" ")])]}},{key:"item.slug",fn:function({ item }){return [_vm._v(" "+_vm._s(item.slug)+" ")]}},{key:"item.status",fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.formatStatus(item.status))+" ")]}}],null,false,1470971914)}),_c('v-pagination',{attrs:{"length":_vm.pageCount},model:{value:(_vm.params.page),callback:function ($$v) {_vm.$set(_vm.params, "page", $$v)},expression:"params.page"}})],1):_vm._e(),(_vm.pages.length === 0 || _vm.pageLength < _vm.params.page)?_c('section',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":[],"loading":_vm.loading,"loading-text":'ロード中...',"no-data-text":'データがありません。',"hide-default-footer":""}})],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }